export const RightUpArrow = () => {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.58149 0.597656C1.25012 0.597656 0.981488 0.866285 0.981488 1.19766C0.981488 1.52903 1.25012 1.79766 1.58149 1.79766H5.21805L1.00967 6.1259C0.778664 6.36348 0.783997 6.74335 1.02158 6.97435C1.25916 7.20535 1.63902 7.20002 1.87002 6.96244L5.97227 2.74334V6.19431C5.97227 6.52568 6.2409 6.79431 6.57227 6.79431C6.90364 6.79431 7.17227 6.52568 7.17227 6.19431V1.19766V0.597656H6.57227H1.58149Z"
        fill="#262626"
      />
    </svg>
  );
};
